import { formatDistanceToNowStrict } from 'date-fns'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomField, {
  CustomFieldComponentType,
  mappingCustomFieldIcon
} from '~/components/CustomField'
import JobViewSalary from '~/components/Jobs/JobViewSalary'
import { FCC } from '~/core/@types/global'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { SuggestionChips } from '~/core/ui/SuggestionChips'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { formatAddressLocation } from '~/core/utilities/common'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { DEFAULT_CURRENCY } from '~/lib/features/candidates/utilities/enum'
import { IJobInfoType } from '~/lib/features/jobs/types'
import { mappingCustomFieldKind } from '~/lib/features/settings/profile-fields/mapping/custom-field-mapping'
import useCustomFieldSettingByUser from '~/lib/features/settings/profile-fields/hooks/use-custom-field-setting-by-user'
import { CustomFieldFormType } from '~/lib/features/settings/profile-fields/types/custom-field'
import If from '~/core/ui/If'

const InfoRow: FCC<{
  label: string
  iconMenus?: LucideIconName
  showEmpty?: boolean
}> = ({ label, iconMenus, showEmpty = false, children }) => {
  return (
    <>
      <div>
        <div className="flex items-center py-1.5">
          {iconMenus ? (
            <IconWrapper
              name={iconMenus}
              className="mr-2 flex-none text-gray-600"
              size={16}
            />
          ) : null}
          <TypographyText className="text-sm text-gray-700">
            {label}
          </TypographyText>
        </div>
      </div>

      <div className="px-2 py-1.5 text-sm text-gray-900">
        {showEmpty ? '-' : children}
      </div>
    </>
  )
}

const JobInfo: FC<
  IJobInfoType & {
    isCompanyKind?: boolean
    configHidden?: {
      [type: string]: boolean
    }
  }
> = (job) => {
  const { isCompanyKind, configHidden } = job
  const [showMore, setShowMore] = useState<boolean>(false)
  const { t } = useTranslation()

  const { data: customFieldViewData } = useCustomFieldSettingByUser({
    objectKind: 'job'
  })

  const showOtherInfo = useCallback(() => {
    setShowMore(true)
  }, [])

  return (
    <div className="grid grid-cols-[160px_1fr]">
      {isCompanyKind ? (
        <InfoRow
          label={`${t('job:detail:summaryInfo:company')}`}
          iconMenus="Building"
          showEmpty={!job?.company?.name}>
          <div>{job?.company?.name}</div>
        </InfoRow>
      ) : null}
      <InfoRow
        label={`${t('job:detail:summaryInfo:tags')}`}
        iconMenus="Tags"
        showEmpty={(job?.tags || [])?.length === 0}>
        <SuggestionChips
          size="sm"
          source={
            job.tags?.map((tag) => ({
              label: tag.name,
              maxLength: 30
            })) || []
          }
          type="default"
        />
      </InfoRow>
      <InfoRow
        label={`${t('job:detail:summaryInfo:location')}`}
        iconMenus="MapPin"
        showEmpty={(job?.jobLocations || [])?.length === 0}>
        {job?.jobLocations?.map((locationItem, index) => (
          <div key={`job-location-${index}`}>
            {formatAddressLocation({
              location: {
                city: locationItem.city,
                state: locationItem.state,
                country: locationItem.country
              }
            })}
          </div>
        ))}
      </InfoRow>
      <If
        condition={
          job?.permittedFields?.salaryFrom &&
          job?.permittedFields?.salaryTo &&
          job?.permittedFields?.currency &&
          job?.permittedFields?.typeOfSalaryDescription
        }>
        <InfoRow
          label={`${t('job:detail:summaryInfo:salary')}`}
          iconMenus="DollarSign">
          <JobViewSalary
            salaryTo={Number(job?.permittedFields?.salaryTo?.value) || 0}
            salaryFrom={Number(job?.permittedFields?.salaryFrom?.value) || 0}
            currency={job?.permittedFields?.currency?.value || DEFAULT_CURRENCY}
            typeOfSalaryDescription={
              job?.permittedFields?.typeOfSalaryDescription?.value || ''
            }
          />
        </InfoRow>
      </If>
      {!configHidden?.headcount ? (
        <InfoRow
          label={`${t('job:detail:summaryInfo:headcount')}`}
          iconMenus="User"
          showEmpty={!job?.headcount}>
          <div>{job?.headcount}</div>
        </InfoRow>
      ) : null}
      <If condition={job?.permittedFields?.employmentType}>
        <InfoRow
          label={`${t('job:detail:summaryInfo:employmentType')}`}
          iconMenus="Briefcase"
          showEmpty={!job?.permittedFields?.employmentTypeDescription?.value}>
          <div>{job?.permittedFields?.employmentTypeDescription?.value}</div>
        </InfoRow>
      </If>
      {!isCompanyKind ? (
        <InfoRow
          label={`${t('job:detail:summaryInfo:department')}`}
          iconMenus="Table"
          showEmpty={!job?.department?.name}>
          <div>{job?.department?.name}</div>
        </InfoRow>
      ) : null}
      <InfoRow
        label={`${t('job:detail:summaryInfo:skills')}`}
        iconMenus="StretchHorizontal"
        showEmpty={(job?.skills || [])?.length === 0}>
        <SuggestionChips
          size="sm"
          source={
            job.skills?.map((skill) => ({
              label: skill,
              maxLength: 30
            })) || []
          }
          type="default"
        />
      </InfoRow>
      {!configHidden?.languages && (
        <InfoRow
          label={`${t('job:detail:summaryInfo:languages')}`}
          iconMenus="Languages"
          showEmpty={(job?.languages || [])?.length === 0}>
          <div className="text-sm text-gray-900">
            {job.languages?.map((lang) => (
              <div key={`lang-${lang.index}`} className="mb-1.5 last:mb-0">
                {lang.languageDescription}
              </div>
            ))}
          </div>
        </InfoRow>
      )}
      {!showMore ? (
        <div>
          <TextButton
            label={`${t('button:showMore')}`}
            size="md"
            underline={false}
            onClick={showOtherInfo}
          />
        </div>
      ) : (
        <>
          <InfoRow
            label={`${t('job:detail:summaryInfo:category')}`}
            iconMenus="Table"
            showEmpty={!job?.jobCategory?.name}>
            <div>{job?.jobCategory?.name}</div>
          </InfoRow>
          <If condition={job?.permittedFields?.jobLevel}>
            <InfoRow
              label={`${t('job:detail:summaryInfo:experienceLevel')}`}
              iconMenus="Zap"
              showEmpty={!job?.permittedFields?.jobLevelDescription?.value}>
              <div>{job?.permittedFields?.jobLevelDescription?.value}</div>
            </InfoRow>
          </If>
          <InfoRow
            label={`${t('job:detail:summaryInfo:educationLevel')}`}
            iconMenus="BookOpen"
            showEmpty={!job?.educationDescription}>
            <div>{job?.educationDescription}</div>
          </InfoRow>
          <InfoRow
            label={`${t('job:detail:summaryInfo:reference_id')}`}
            iconMenus="FileBadge"
            showEmpty={!job?.referenceId}>
            <div>{job?.referenceId}</div>
          </InfoRow>
          <If condition={job?.permittedFields?.talentPools}>
            <InfoRow
              label={`${t('job:detail:summaryInfo:talent_pools')}`}
              iconMenus="FolderSearch"
              showEmpty={
                (job.permittedFields?.talentPools?.value || [])?.length === 0
              }>
              <SuggestionChips
                size="md"
                source={
                  (job.permittedFields?.talentPools?.value || [])?.map(
                    (talentPool) => ({
                      label: talentPool.name,
                      maxLength: 30
                    })
                  ) || []
                }
                type="default"
              />
            </InfoRow>
          </If>
          <InfoRow
            label={`${t('job:detail:summaryInfo:posted')}`}
            iconMenus="Clock"
            showEmpty={!job.createdAt}>
            {job?.createdAt && (
              <Tooltip
                align="start"
                content={defaultFormatDate(new Date(job?.createdAt))}>
                <TypographyText>
                  {formatDistanceToNowStrict(new Date(job?.createdAt))}
                </TypographyText>
              </Tooltip>
            )}
          </InfoRow>
          {!configHidden?.customFields &&
            customFieldViewData.map((customField) => {
              const customFieldData = (job.customFields || []).find(
                (customFieldValue) =>
                  String(customFieldValue.customSettingId) ===
                  String(customField.id)
              )
              return (
                <>
                  <CustomField
                    type={customField.type as CustomFieldComponentType['type']}
                    display="view_horizontal"
                    name={customField.name}
                    label={customField.label}
                    error={{}}
                    value={
                      customFieldData?.fieldKind === 'array'
                        ? customFieldData?.selectedOptionKeys?.[0]
                        : customFieldData?.value
                    }
                    onChange={() => {}}
                    extraProps={{
                      options: customField.selectOptions
                    }}
                  />
                </>
              )
            })}
        </>
      )}
    </div>
  )
}

export default JobInfo
