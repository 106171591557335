import Link from 'next/link'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import useStaticData from 'src/hooks/data/use-static-data'
import HTMLDisplay from '~/components/HTMLDisplay'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import { FCC } from '~/core/@types/global'
import { Avatar } from '~/core/ui/Avatar'
import { Badge, IColorBadgeType } from '~/core/ui/Badge'
import { Divider } from '~/core/ui/Divider'
import Empty from '~/core/ui/Empty'
import IconWrapper from '~/core/ui/IconWrapper'
import { ScrollArea } from '~/core/ui/ScrollArea'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsTriggerView
} from '~/core/ui/Tabs'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { adminCanAction } from '~/core/utilities/permission'
import JobDetailSkeleton from '~/features/jobs/[id]/tabs/detail/components/JobDetailSkeleton'
import JobInfo from '~/features/jobs/[id]/tabs/detail/components/JobInfo'
import { InterviewFeedbackDetailProps } from '~/lib/features/calendar/types'
import { JOB_COLOR_STAGE_NAME } from '~/lib/features/jobs/utilities/enum'
import useBrowserTab from '~/lib/hooks/use-browser-tab'
import useBoundStore from '~/lib/store'
import ProfileViewInFeedbackDetail from './ProfileViewInFeedbackDetail'
import { IStageType } from '~/lib/features/jobs/types'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { Container } from '~/core/ui/Container'
import { mappingColorByStageType } from '~/lib/features/settings/hiring-pipelines/utilities/common'
import { IDotColorProps } from '~/core/ui/Dot'

interface InterviewFeedbackLeftViewProps {
  isDrawer: boolean
  interviewsShow?: InterviewFeedbackDetailProps
}

const TabContentView: FCC<{ value: string }> = ({ children, value }) => (
  <TabsContent value={value} className="mt-0">
    <div className="py-4">{children}</div>
  </TabsContent>
)

const InterviewFeedbackLeftView = (props: InterviewFeedbackLeftViewProps) => {
  const { isDrawer, interviewsShow } = props
  const { t, i18n } = useTranslation()
  const { user, currentRole } = useBoundStore()
  const stageTypes = useStaticData({
    keyName: 'agency_stageTypes',
    locale: i18n.language
  })
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })

  const hiringTeamIds = useMemo(
    () =>
      !!interviewsShow
        ? [
            interviewsShow.job?.owner?.id,
            ...(interviewsShow.job?.jobRecruiters || []).map(
              (item) => item?.user?.id
            )
          ].map((id) => Number(id))
        : [],
    [interviewsShow]
  )

  const TABS_KEY = [
    {
      value: 'guideline',
      label: `${t('interview:feedback_modal:guideline')}`
    },
    {
      value: 'cv_resume',
      label: `${t('interview:feedback_modal:profile')}`
    },
    {
      value: 'job_details',
      label: `${t('interview:feedback_modal:jobDetails')}`
    }
  ]

  const getHeight = useClassBasedTopSpace({
    34: isDrawer === false ? 'calc(100vh - 229px)' : 'calc(100vh - 195px)',
    default: 'calc(100vh - 195px)'
  })
  const tabControl = useBrowserTab({
    defaultValue: interviewsShow?.jobIkit?.guideline
      ? TABS_KEY[0]?.value
      : TABS_KEY[1]?.value,
    queryKeyName: 'tabs_interview_detail',
    pushState: !!isDrawer
  })
  const getColorClassName = (stageTypes || []).filter(
    (s: IStageType) =>
      String(s.id) === String(interviewsShow?.jobStage?.stageTypeId)
  )?.[0]?.colorClassName

  if (!interviewsShow) return null
  return (
    <>
      <div className="h-full w-[61.8%] pt-4">
        <Tabs {...tabControl} className="h-full">
          <div className="flex h-full flex-col overflow-hidden">
            <div className="flex-none border-b border-b-gray-100 px-6">
              <div className="mb-3 flex items-center">
                <div className="mr-3">
                  <Avatar
                    size="lg"
                    color="#FFFFFF"
                    alt={interviewsShow?.profile?.fullName}
                    src={interviewsShow?.profile?.avatarVariants?.thumb?.url}
                  />
                </div>
                <div>
                  <TypographyText className="text-base font-semibold text-gray-900">
                    {interviewsShow?.profile?.fullName}
                  </TypographyText>
                  <div className="flex items-center space-x-2">
                    <div className="flex items-center space-x-1">
                      <TypographyText className="flex-none text-sm text-gray-600">
                        {t('interview:feedback_modal:interviewingFor')}
                      </TypographyText>
                      <Tooltip content={interviewsShow?.applicant?.job?.title}>
                        <TypographyText className="line-clamp-1 text-sm font-medium text-gray-900">
                          {interviewsShow?.applicant?.job?.title}
                        </TypographyText>
                      </Tooltip>

                      {(hiringTeamIds.includes(Number(user.id)) ||
                        adminCanAction(currentRole?.code)) && (
                        <Link
                          target="_blank"
                          href={pathConfiguration?.jobs?.detail(
                            Number(interviewsShow?.applicant?.job?.id)
                          )}>
                          <IconWrapper
                            name="ExternalLink"
                            size={16}
                            className="text-gray-600"
                          />
                        </Link>
                      )}
                    </div>
                    <div className="flex-none">
                      <Badge
                        color={
                          JOB_COLOR_STAGE_NAME(
                            getColorClassName
                          ) as IColorBadgeType
                        }
                        dotColor={
                          mappingColorByStageType(
                            getColorClassName
                          ) as IDotColorProps
                        }
                        size="md"
                        radius="rounded"
                        type="dotLeading">
                        {interviewsShow?.jobStage?.stageLabel}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>

              <TabsList size="sm">
                {(interviewsShow?.jobIkit?.guideline
                  ? TABS_KEY
                  : TABS_KEY.filter((i) => i.value !== 'guideline')
                ).map((tab, index) => {
                  return (
                    <TabsTrigger
                      key={`tab-${index}`}
                      size="sm"
                      gapSize="sm"
                      value={tab?.value}>
                      <TabsTriggerView
                        size="sm"
                        session={{ value: tab.value, label: tab.label }}
                      />
                    </TabsTrigger>
                  )
                })}
              </TabsList>
            </div>

            <TabContentView value="guideline">
              <ScrollArea
                rootStyle={{
                  height: getHeight
                }}>
                <Container overrideClass="px-6">
                  {interviewsShow?.jobIkit?.guideline ? (
                    <HTMLDisplay
                      className="text-sm text-gray-900"
                      content={interviewsShow?.jobIkit?.guideline}
                    />
                  ) : (
                    <div className="mt-[84px]">
                      <Empty
                        title={`${t('interview:feedback_modal:labelEmpty')}`}
                        description={`${t(
                          'interview:feedback_modal:descriptionEmpty'
                        )}`}
                      />
                    </div>
                  )}
                </Container>
              </ScrollArea>
            </TabContentView>

            <TabContentView value="cv_resume">
              <ScrollArea
                rootStyle={{
                  height: getHeight
                }}>
                <Container overrideClass="px-6">
                  <ProfileViewInFeedbackDetail data={interviewsShow?.profile} />
                </Container>
              </ScrollArea>
            </TabContentView>
            <TabContentView value="job_details">
              {!interviewsShow?.job ? (
                <Container overrideClass="px-6">
                  <JobDetailSkeleton />
                </Container>
              ) : (
                <ScrollArea
                  rootStyle={{
                    height: getHeight
                  }}>
                  <Container overrideClass="px-6">
                    <JobInfo
                      isCompanyKind={isCompanyKind}
                      configHidden={{
                        headcount: true,
                        languages: true,
                        customFields: true
                      }}
                      {...interviewsShow?.job}
                    />
                    <Divider className="my-4" />
                    <div>
                      <div>
                        <TypographyText className="text-base font-medium text-gray-900">
                          {t('job:detail:title')}
                        </TypographyText>
                        <HTMLDisplay
                          className="prose prose-sm mt-2 max-w-full text-sm"
                          content={interviewsShow?.job?.pitch}
                        />
                      </div>
                      <div className="mt-6">
                        <TypographyText className="text-base font-medium text-gray-900">
                          {t('job:detail:description')}
                        </TypographyText>
                        <HTMLDisplay
                          className="prose prose-sm mt-2 max-w-full text-sm"
                          content={interviewsShow?.job?.description}
                        />
                      </div>
                    </div>
                  </Container>
                </ScrollArea>
              )}
            </TabContentView>
          </div>
        </Tabs>
      </div>
    </>
  )
}
export default InterviewFeedbackLeftView
