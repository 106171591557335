import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IAttachmentsFile } from '~/core/@types/global'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { openAlert } from '~/core/ui/AlertDialog'
import {
  AlertCircleFill,
  CircleCheckFill,
  ExclamationFill
} from '~/core/ui/FillIcons'
import { IconButton } from '~/core/ui/IconButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import MutationBulkUploadFile from '~/lib/features/candidates/graphql/mutation-upload-file'
import { IUploadDrawerJob } from '~/lib/features/candidates/types'

interface UploadCVFileUploadProps {
  uploadDrawerJobId?: IUploadDrawerJob
  item: IAttachmentsFile
  index: number
  fileIndex: number
  setFileIndex: (fileIndex: number) => void
  onUpdateProfileId: ({ item }: { item: IAttachmentsFile }) => void
  onDeletePendingFile?: () => void
  onDeleteProfile?: () => void
}

const UploadCVFileUpload = (props: UploadCVFileUploadProps) => {
  const {
    uploadDrawerJobId,
    item,
    index,
    fileIndex,
    setFileIndex,
    onUpdateProfileId,
    onDeletePendingFile,
    onDeleteProfile
  } = props
  const { t } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const isActiveFile = index === fileIndex

  const callUploadFile = useCallback(
    async (file: File) => {
      await clientGraphQL
        .mutation(MutationBulkUploadFile, {
          files: [file]
        })
        .toPromise()
        .then(
          (result: {
            error: { graphQLErrors: Array<object> }
            data: {
              [key: string]: {
                profiles?: Array<{
                  id: string
                  applicants?: Array<{
                    id?: number
                  }>
                  warning?: Array<{ short?: string; full?: string }>
                }>
              }
            }
          }) => {
            if (result.error) {
              catchErrorFromGraphQL({
                error: result.error
              })

              const { graphQLErrors } = result.error
              if (graphQLErrors.length === 1) {
                const parseErrors = JSON.parse(JSON.stringify(graphQLErrors[0]))
                const keys =
                  parseErrors?.message &&
                  typeof parseErrors?.message === 'string'
                    ? parseErrors?.extensions?.errors
                    : parseErrors?.message?.extensions?.errors
                const statusCode =
                  parseErrors?.message &&
                  typeof parseErrors?.message === 'string'
                    ? parseErrors?.extensions?.http_code
                    : parseErrors?.message?.extensions?.http_code

                if (statusCode === 422) {
                  return onUpdateProfileId({
                    item: {
                      ...item,
                      status: 'error',
                      statusDescription: keys
                        .map((item: { message: string }) => item.message)
                        .join(', ')
                    }
                  })
                } else {
                  return onUpdateProfileId({
                    item: {
                      ...item,
                      status: 'error',
                      statusDescription: 'Network Error'
                    }
                  })
                }
              }

              return
            }

            const { profilesBulkCvsUpload } = result.data
            if (profilesBulkCvsUpload.profiles?.length) {
              const getProfile = profilesBulkCvsUpload.profiles[0]

              if (getProfile?.warning?.length) {
                onUpdateProfileId({
                  item: {
                    ...item,
                    id: getProfile?.applicants?.[0]?.id
                      ? String(getProfile?.applicants?.[0]?.id)
                      : '',
                    profileId: getProfile?.id,
                    status: 'warning',
                    statusDescription: getProfile.warning
                      .map((item) => item.short)
                      .join(', ')
                  }
                })
              } else {
                onUpdateProfileId({
                  item: {
                    ...item,
                    id: getProfile?.applicants?.[0]?.id
                      ? String(getProfile?.applicants?.[0]?.id)
                      : '',
                    profileId: getProfile?.id,
                    status: 'upload'
                  }
                })
              }
            } else {
              onUpdateProfileId({
                item: {
                  ...item,
                  status: 'error',
                  statusDescription: 'Network Error'
                }
              })
            }

            return
          }
        )
    },
    [clientGraphQL, item, onUpdateProfileId]
  )

  useEffect(() => {
    if (item.id === undefined && item.file && item.status === 'pending') {
      callUploadFile(item.file)
    }
  }, [])

  const deletePendingFile = async () => {
    onDeletePendingFile && onDeletePendingFile()
    if (index === fileIndex) {
      setFileIndex(0)
    }
  }

  const deleteProfile = async () => {
    openAlert({
      isPreventAutoFocusDialog: false,
      className: 'w-[480px]',
      title: `${t('common:modal:delete_cv_title')}`,
      description: t('common:modal:delete_cv_description'),
      actions: [
        {
          label: `${t('button:cancel')}`,
          type: 'secondary',
          size: 'sm'
        },
        {
          isCallAPI: true,
          label: `${t('button:delete')}`,
          type: 'destructive',
          size: 'sm',
          onClick: async () => {
            if (onDeleteProfile) {
              await onDeleteProfile()
            }
          }
        }
      ]
    })
  }

  return (
    <div
      className={cn(
        'group flex min-h-[36px] cursor-pointer px-6 py-2 hover:bg-gray-50',
        isActiveFile ? 'bg-gray-50' : ''
      )}>
      <div
        className="w-4 py-0.5"
        onClick={() => {
          if (item.status !== 'error') {
            setFileIndex(index)
          }
        }}>
        {item.status === 'error' ? (
          <AlertCircleFill className="!fill-red-500" />
        ) : null}
        {item.status === 'upload' ? (
          <CircleCheckFill className="!fill-green-500" />
        ) : null}
        {item.status === 'warning' ? (
          <ExclamationFill className="!fill-orange-500" />
        ) : null}
        {item.status === 'pending' ? (
          <IconWrapper
            className="animate-spin text-gray-400 dark:text-gray-400"
            name="Loader2"
            size={16}
          />
        ) : null}
      </div>

      <div
        className="flex-1 truncate"
        onClick={() => {
          if (item.status !== 'error') {
            setFileIndex(index)
          }
        }}>
        <Tooltip
          align="start"
          classNameAsChild={cn(
            'truncate block text-sm font-medium px-2',
            isActiveFile
              ? 'text-primary-400'
              : item.status === 'error'
              ? 'text-gray-400'
              : 'text-gray-700'
          )}
          content={item.name}>
          {item.name}
        </Tooltip>
        {item.status === 'error' ? (
          <Tooltip
            align="start"
            classNameAsChild={cn('truncate block text-xs px-2 text-red-500')}
            content={item.statusDescription}>
            {item.statusDescription}
          </Tooltip>
        ) : null}
        {item.status === 'warning' ? (
          <Tooltip
            align="start"
            classNameAsChild={cn('truncate block text-xs px-2 text-orange-500')}
            content={item.statusDescription}>
            {item.statusDescription}
          </Tooltip>
        ) : null}
      </div>

      <div className="-my-0.5 hidden group-hover:block">
        {['upload', 'warning'].includes(item.status) ? (
          <Tooltip content={t('tooltip:delete')}>
            <IconButton
              type="secondary-destructive"
              size="xs"
              iconMenus="Trash2"
              onClick={deleteProfile}
            />
          </Tooltip>
        ) : null}
        {['pending', 'error'].includes(item.status) ? (
          <Tooltip content={t('tooltip:close')}>
            <IconButton
              type="secondary-destructive"
              size="xs"
              iconMenus="X"
              onClick={deletePendingFile}
            />
          </Tooltip>
        ) : null}
      </div>
    </div>
  )
}

export default UploadCVFileUpload
